export function createOpeningRequest(
  formValues: OpeningFormValues,
  status: PublishStatus,
  languages: OpeningLanguage[],
  metaImage: string | null,
  pipeline: string | null,
  publishedAt: string | undefined | null,
  expiresAt: string | undefined | null,
): OpeningRequest {
  return {
    title: formValues.title,
    status: status,
    departmentId: formValues.departmentId,
    cityCountry: formValues.cityCountry,
    location: { ...formValues.location.location, srid: 0 },
    descriptionRolesResponsibilities:
      typeof formValues.descriptionRolesResponsibilities !== 'string'
        ? JSON.stringify(formValues.descriptionRolesResponsibilities)
        : formValues.descriptionRolesResponsibilities,
    education: formValues.education,
    yearsOfProfessionalExperience: formValues.yearsOfProfessionalExperience
      ? parseInt(formValues.yearsOfProfessionalExperience)
      : null,
    workplaceSpecifies:
      formValues.workplaceSpecifies &&
      typeof formValues.workplaceSpecifies !== 'string'
        ? JSON.stringify(formValues.workplaceSpecifies)
        : formValues.workplaceSpecifies,
    typeOfWorkplace: formValues.typeOfWorkplace,
    salaryRange: formValues.salaryRange,
    workingHours: formValues.workingHoursGroup[0],
    numberOfWorkingHours: formValues.numberOfWorkingHours
      ? parseInt(formValues.numberOfWorkingHours)
      : null,
    partTimePeriod: formValues.partTimePeriod,
    contractType: formValues.contractType,
    temporaryProbationPeriod: Boolean(
      formValues.temporaryPeriodGroup &&
        formValues.temporaryPeriodGroup.includes('with-probation'),
    ),
    temporaryProbationPeriodDuration:
      typeof formValues.temporaryProbationPeriodDuration === 'string'
        ? parseInt(formValues.temporaryProbationPeriodDuration)
        : formValues.temporaryProbationPeriodDuration,
    indefiniteProbationPeriod: Boolean(
      formValues.indefinitePeriodGroup &&
        formValues.indefinitePeriodGroup.includes('with-probation'),
    ),
    indefiniteProbationPeriodDuration:
      typeof formValues.indefiniteProbationPeriodDuration === 'string'
        ? parseInt(formValues.indefiniteProbationPeriodDuration)
        : formValues.indefiniteProbationPeriodDuration,
    careerLevel: formValues.careerLevel,
    benefits:
      formValues.benefits && typeof formValues.benefits !== 'string'
        ? JSON.stringify(formValues.benefits)
        : formValues.benefits,
    metaTitle: formValues.metaTitle,
    metaDescription: formValues.metaDescription,
    metaImage: metaImage,
    languages: languages,
    tags: formValues.tags.length ? { names: formValues.tags } : null,
    expiresAt: expiresAt || null,
    publishedAt: publishedAt || null,
    pipelineId: pipeline,
  };
}
